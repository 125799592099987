import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';
import ContentWrapper from './ContentWrapper';
import SocialMediaIcons from './SocialMediaIcons';

const Grid = styled.div`
  min-height: 100%;
  width: 100%;
  @media (min-width: 600px) {
    display: grid;
    grid-template-rows: auto minmax(100px, 1fr) auto auto;
    row-gap: calc(var(--spacing) * 4);
  }
`;

const Header = styled.div`
  background: var(--black);
  color: var(--pink);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(var(--spacing) * 2);
  h2 {
    font-size: 32px;
    margin: calc(var(--spacing) * 2) 0;
    text-shadow: 1px 1px black;
  }
  @media (max-width: 870px) {
    height: auto;
    padding: calc(var(--spacing) * 2);
    h2 {
      margin-bottom: var(--spacing);
    }
  }
  a {
    margin-top: calc(var(--spacing) / 2);
    color: var(--white);
  }
`;

const Footer = styled.div`
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc(var(--spacing) * 2);
  p {
    font-size: 16px;
    margin-top: 4px 0 0 0;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

export default function PageGrid({
  heading,
  socialMedia,
  socialMediaTitle,
  footer,
  children,
}) {
  const intl = useIntl();
  return (
    <ContentWrapper>
      <Grid>
        <Header>
          <h2>{heading}</h2>
          <SocialMediaIcons data={socialMedia} title={socialMediaTitle} />
        </Header>
        {children}
        <Footer>
          {footer && (
            <p className="body-copy">
              {intl.formatMessage({ id: 'contact' })}
              {`: ${footer}`}
            </p>
          )}
        </Footer>
      </Grid>
    </ContentWrapper>
  );
}
