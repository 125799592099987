import React from 'react';
import styled from 'styled-components';
import {
  GrInstagram,
  GrFacebook,
  GrSpotify,
  GrTwitter,
  GrYoutube,
} from 'react-icons/gr';
import { FaBandcamp } from 'react-icons/fa';

const SocialLinks = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SocialLinksList = styled.ul`
  --direction: row;
  display: flex;
  flex-direction: var(--direction);
  align-items: center;
  list-style: none;
  padding: 0;
  a {
    margin: 0 0 0 8px;
    transition: color 0.3s;
    &:hover {
      color: var(--mint);
    }
  }
  svg {
    vertical-align: bottom;
  }
`;

const createLinks = (data, title) => {
  const { social } = data;
  if (!data.social) return;
  let elements = [];
  Object.entries(social).forEach(([key, value]) => {
    if (!value) return;
    let icon;
    if (key === 'facebook') icon = <GrFacebook />;
    if (key === 'instagram') icon = <GrInstagram />;
    if (key === 'spotify') icon = <GrSpotify />;
    if (key === 'twitter') icon = <GrTwitter />;
    if (key === 'youtube') icon = <GrYoutube />;
    if (key === 'bandcamp') icon = <FaBandcamp />;
    const listItem = (
      <li key={key}>
        <a href={social[key]} title={`Find ${title} on ${key}`}>
          {icon}
        </a>
      </li>
    );
    elements = [...elements, listItem];
  });
  return elements;
};

export default function SocialMediaIcons({ data, title, direction }) {
  return (
    <SocialLinks>
      <SocialLinksList style={{ '--direction': direction }}>
        {createLinks(data, title)}
      </SocialLinksList>
    </SocialLinks>
  );
}
