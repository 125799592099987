/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import UpcomingEvents from '../components/UpcomingEvents';
import PageGrid from '../components/PageGrid';

const ArtistImage = styled.div`
  @media (max-width: 870px) {
    margin-right: 0;
    width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: calc(var(--spacing) * 2);
  h3 {
    text-align: center;
    margin-bottom: calc(var(--spacing) * 2);
    padding-bottom: var(--spacing);
    display: block;
  }
`;

const Article = styled.article`
  max-width: 70ch;
  margin: 0 auto;
`;

const BackButton = styled.div`
  background: var(--white);
  border: 0;
  display: inline-flex;
  align-items: center;
  margin: 0 0 16px 2px;
  padding: 8px;
  span {
    padding-top: 4px;
  }
`;

export default function VenuePage({ data: { venue, events } }) {
  const overrides = {
    h2: (props) => <h2 className="title" {...props} />,
    normal: (props) => <p className="body-copy" {...props} />,
  };
  const serializers = {
    types: {
      block: (props) =>
        // Check if we have an override for the “style”
        overrides[props.node.style]
          ? // if so, call the function and pass in the children, ignoring
            // the other unnecessary props
            overrides[props.node.style]({ children: props.children })
          : // otherwise, fallback to the provided default with all props
            BlockContent.defaultSerializers.types.block(props),
    },
  };
  return (
    <>
      <SEO
        title={venue.venueName}
        image={venue?.venueImage?.asset?.fluid?.src}
        description={venue.description}
      />
      <PageGrid
        heading={venue.venueName}
        socialMedia={venue}
        socialMediaTitle={venue.venueName}
        footer={venue.email || venue.phone}
      >
        <Container>
          {venue.venueImage && (
            <ArtistImage>
              <Img fluid={venue.venueImage.asset.fluid} />
            </ArtistImage>
          )}
          <UpcomingEvents data={events} />
        </Container>
        <Article>
          <h3>Venue Information</h3>
          <BlockContent
            blocks={venue._rawVenueInfo}
            serializers={serializers}
          />
        </Article>
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query($slug: String) {
    venue: sanityVenue(slug: { current: { eq: $slug } }) {
      website
      venueName
      _rawVenueInfo
      email
      phone
      website
      social {
        facebook
        instagram
        spotify
        twitter
        youtube
      }
      venueImage {
        asset {
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    events: allSanityEvent(
      filter: { location: { elemMatch: { slug: { current: { eq: $slug } } } } }
    ) {
      nodes {
        id
        slug {
          current
        }
        eventTitle
        eventStartDate
        eventArtists {
          artistName
        }
      }
    }
  }
`;
