import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FormattedDate, FormattedDateParts } from 'gatsby-plugin-intl';

const Events = styled.div`
  background: var(--black);
  color: var(--white);
  padding: calc(var(--spacing) * 2);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    --rotate: -0.3deg;
    border-bottom: dotted 2px #ccc;
    margin-bottom: var(--spacing);
    transform: rotate(var(--rotate));
    transition: transform 0.3s;
    &:nth-child(1) {
      --rotate: 0.6deg;
    }
    &:nth-child(2) {
      --rotate: -0.3deg;
    }
    &:nth-child(3) {
      --rotate: 0.5deg;
    }
    &:hover {
      --rotate: 0;
    }
  }
  a {
    color: var(--white);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: calc(var(--spacing) / 2);
    transition: all 0.3s;
    &:hover,
    &:active {
      background: var(--pink);
      color: var(--white);
    }
  }
`;

const createEvents = (data) => {
  if (data.length >= 1) {
    return (
      <ul>
        {data.map((event) => (
          <li key={event.id}>
            <Link to={`/events/${event.slug.current}`}>
              <span>{event.eventStartDate}</span>
              {event.eventTitle || event.location[0].venueName || ''}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div>
      <h4>Boooo!! Nothing here!</h4>
      <p>Go and listen to your Spotify</p>
    </div>
  );
};

export default function UpcomingEvents({ data: { nodes }, type }) {
  const events = nodes.map((event) => {
    let title;
    if (type === 'artist') {
      title = event.eventTitle || event.location[0].venueName || '';
    } else {
      title =
        event.eventTitle ||
        event.eventArtists.map((artist) => artist.artistName).join(', ');
    }
    return (
      <li key={event.id}>
        <Link to={`/events/${event.slug.current}`}>
          <span>
            <FormattedDate
              value={event.eventStartDate}
              // year="numeric"
              month="2-digit"
              day="2-digit"
              weekday="short"
            />
          </span>
          {title}
        </Link>
      </li>
    );
  });

  return (
    <Events>
      {nodes.events && <h3>Upcoming Events</h3>}
      <ul>{events}</ul>
    </Events>
  );
}
