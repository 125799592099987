import React from 'react';
import styled from 'styled-components';

const WrapperElement = styled.div`
  background: var(--white);
  border: solid 2px var(--black);
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.6);
  max-width: var(--page-width);
  width: 100%;
  padding: calc(var(--spacing) * 2);
`;

export default function ContentWrapper({ children }) {
  return <WrapperElement>{children}</WrapperElement>;
}
